<template>
    <v-card max-width="1100px">
        <v-toolbar class="mb-5" flat dark color="primary">
            <v-btn icon dark @click="fecharDialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Reapresentação de titulo</v-toolbar-title>
        </v-toolbar>

        <DetalheTituloReapresentacao
            :tituloParaReapresentacao="titulo"
            :loadingReapresentacao="loadingReapresentacao"
            @fecharModal="fecharDialog"
            @reapresentarTitulo="reapresentarTitulo"
        />
        <v-dialog
            v-if="dialogIrregularidades"
            v-model="dialogIrregularidades"
            max-width="800px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title>
                    <v-icon large class="ma-2"> mdi-information </v-icon>Houve
                    irregularidades no título
                </v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-item-group>
                            <v-list-item
                                v-for="(irregularidade,
                                index) in irregularidades"
                                :key="index"
                            >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ irregularidade }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card-text>
                <v-card-actions class="d-flex justify-end my-3">
                    <v-btn
                        color="primary"
                        class="white--text d-flex justify-center pl-5"
                        @click="dialogIrregularidades = false"
                    >
                        Fechar
                        <v-icon large>
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <Snackbar
            v-if="snackbar"
            :mostrarSnackbar="snackbar"
            :corSnackbar="colorSnackbar"
            :mensagemSnackbar="mensagemSnackbar"
            @fecharSnackbar="snackbar = false"
        />
    </v-card>
</template>
<script>
import DetalheTituloReapresentacao from '@/components/organisms/DetalheTituloReapresentacao.vue';
import TituloService from '@/services/TituloService';
import { validarRemessa } from '@/helpers/ValidadorRemessa';
import Vue from 'vue';
export default {
    name: 'reapresentacao-titulo',
    components: {
        DetalheTituloReapresentacao
    },
    data() {
        return {
            dialogIrregularidades: false,
            primaryColorFont: '#757575',
            irregularidades: [],
            loadingReapresentacao: false,
            snackbar: false,
            snackbarColor: 'green white--text',
            colorSnackbar: 'success',
            mensagemSnackbar: 'Título reapresentado com sucesso!'
        };
    },
    props: {
        titulo: {
            type: Object,
            required: true
        }
    },
    methods: {
        fecharDialog() {
            this.$emit('fecharModal');
        },
        reapresentarTitulo(titulo) {
            let tituloService = new TituloService(Vue.http, this.$store);
            let tituloFormatado = this.formatarObjeto(titulo);
            this.loadingReapresentacao = true;

            tituloService
                .reapresentarTitulo(tituloFormatado)
                .then(response => {
                    if (response.body.resposta[0].irregularidades) {
                        this.irregularidades = response.body.resposta[0].irregularidades.split(
                            ','
                        );
                        this.dialogIrregularidades = true;
                        return;
                    } else {
                        this.snackbar = true;
                        setTimeout(() => {
                            this.$emit('reloadTable');
                            this.fecharDialog();
                        }, 1000);
                    }
                })
                .catch(error => {
                    this.fecharDialog();
                    console.log(error);
                })
                .finally(() => {
                    this.loadingReapresentacao = false;
                });
        },

        formatarObjeto(obj) {
            let remessaValidada = validarRemessa(obj);
            return remessaValidada;
        }
    }
};
</script>
